import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS',
    'Authorization':API_KEY
}
const APPLICATION_KEY = process.env.REACT_APP_ApplicationKey
const ApplicationKey = process.env.REACT_APP_ApplicationKey;
// export const gettradeList = async (data) => {
//     // 
    
//     try {
//         const response = await axios({
//             method: 'GET',
//             url: `${API_URL}/Trades/GetTrades?UserId=${data?.id}&pageNumber=${data?.pageNumber}&pageSize=${data?.pageSize}&type=${data.type}&searchText=${data.search}&ApplicationKey=${ApplicationKey}`,
//             mode: 'cors',
//             headers,
//             // data: {
//             //   firstName: 'Fred',
//             //   lastName: 'Flintstone'
//             // }
//         });
//         if (response?.status === 200) {
//             return response
//         }
//         else if (response?.status === 401) {
//             alert('Not authorized.')
//         } else if (response?.status >= 500) {
//             alert('Something went wrong')
//         }

//     } catch (error) {
        
//     }
// }
export const gettradeList = async (data) => {
    let url;
  
    if (data.search.trim() === '') {
      
      url = `${API_URL}/Trades/GetTrades?UserId=${data.id}&pageNumber=${data.pageNumber}&type=${data.type}&pageSize=${data.pageSize}&ApplicationKey=${ApplicationKey}`;
    } else {
     
      url = `${API_URL}/Trades/GetTrades?UserId=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&type=${data.type}&searchText=${data.search}&ApplicationKey=${ApplicationKey}`;
    }
  
    try {
      const response = await axios({
        method: 'GET',
        url: url,
        mode: 'cors',
        headers,
      });
  
      if (response?.status === 200) {
        return response;
      } else if (response?.status === 401) {
        alert('Not authorized.');
      } else if (response?.status >= 500) {
        alert('Something went wrong');
      }
    } catch (error) {
      // Handle error
    }
  };
  export const getorderHistory = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTradeHistory?TransactionNo=${data}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getClosetradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetCloseTradeList?UserId=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const modifyOpenTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/ModifyTrade?TradeId=${data.TradeId}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const GetExchangeRate = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetExchangeRate/${data.CurrencyFrom}/${data.CurrencyTo}?ApplicationKey=${ApplicationKey} `,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const GetExchangeRateTrade = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetExchangeRate/${data}?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const GetOpenTradeListByUserId = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTradeList?UserId=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&&type=${data.type}&portfolioId=${data.portfolioId}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const updateOpenTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/UpdateOpenTrade?transactionNo=${data.transactionNo}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const getOpentradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTradeList?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&UserId=${data.id}&PortfolioId=${data.portfolioId}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getOpentradeListFinancelYear = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetUserTrades?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&UserId=${data.id}&PortfolioId=${data.portfolioId}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const deleteTrade = async (id) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/DeleteTrade?id=${id}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const RemoveUserTrade = async (TradeId , UserId) => {
    debugger
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/RemovePendingTrade?TradeId=${TradeId}&UserId=${UserId}`,

            mode: 'cors',
            headers,
            
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const closeOpenTrade = async (id) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${API_URL}/Trades/CloseTrade?tradeid=${id}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const editTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/UpdateTrade?TradeId=${data?.tradeId}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getWatchList = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetWatchList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&UserId=${data.id}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const removeWatchList = async (id) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/DeleteWatchList?id=${id}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const AddWatchList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddWatchList?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getStockDetails = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDetails/${data.tradeCode}/${data.stockDate}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const getStockChartDetails = async () => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDetails/AAPL/1w?ApplicationKey=${ApplicationKey} `,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getStockNews = async (tradeCode) => {
    // console.log(tradeCode ,"stocknews")
    try {
        const response = await axios({
            method: 'GET',
            // url: `${API_URL}/Crypto/GetCryptoNews/CRYPTO:${tradeCode}?ApplicationKey=${ApplicationKey}`,
            url: `${API_URL}/Rapid/GetNews/{tradeCode}?ApplicationKey=${ApplicationKey}`,

            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const getStockIncomeStatement = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockIncomeStatement/${tradeCode}/30&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}



export const getStockBalanceSheet = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockBalanceSheet/${tradeCode}/30?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}



export const getStockCashFlow = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockCashFlow/${tradeCode}/30?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const AddOpenTradeAPI = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddOpenTrade?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}


export const getDevident = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDividends/${data.tradeCode}/${data.interval}?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getStockStats = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockStats/${stockCode}?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const getCryptoIcons = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `https://rest.coinapi.io/v1/assets/icons/32?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers:{
                'X-CoinAPI-Key':'6AE6BEA7-932F-4087-BEB0-1F39CB915396'
            },
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getCryptoSymbols = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Crypto/GetIcons?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const GetInvestmentReturnWeekly = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetInvestmentReturnWeekly/${data.CurrencyFrom}/${data.CurrencyTo}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const GetInvestmentReturnMonthly = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetInvestmentReturnMonthly/${data.CurrencyFrom}/${data.CurrencyTo}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getInflationRate = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetInflationRate?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}